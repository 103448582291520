import axios from 'axios';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import Person from '@/model/person';
import { Commit, Dispatch } from 'vuex';
import { HttpStatus } from '@/model/enums/http-status';
import { i18n } from '@/i18n';
import { PersonRequest } from '@/model/request/person-request';
import { PersonCategory } from '@/model/person-category';
import { State } from '@/model/enums/state';
import { Module } from 'vuex';
import PersonFilterRequest from '@/model/request/filter/person-filter-request';

type PersonStorage = Module<
  {
    pageableOperationalUnitPeople: Pageable<Person> | {};
    uncategorizedPeople: Person[];
  },
  unknown
>;

export default {
  namespaced: true,
  state: {
    pageableOperationalUnitPeople: {},
    uncategorizedPeople: [],
  },
  mutations: {
    setPageableOperationalUnitPeople(
      state: { pageableOperationalUnitPeople: Pageable<Person> },
      payload: Pageable<Person>,
    ) {
      if (payload.currentPage === 0) {
        state.pageableOperationalUnitPeople = payload;
      } else {
        state.pageableOperationalUnitPeople.items = state.pageableOperationalUnitPeople.items.concat(payload.items);
        state.pageableOperationalUnitPeople.currentPage = payload.currentPage;
      }
    },
    setUncategorizedPeople(state: { uncategorizedPeople: Person[] }, payload: Person[]) {
      state.uncategorizedPeople = payload;
    },
    setUncategorizedPerson(state: { uncategorizedPeople: Person[] }, payload: Person) {
      const modifiedPerson = state.uncategorizedPeople.find((person: Person) => person.id === payload.id);
      if (modifiedPerson) {
        Object.assign(modifiedPerson, payload);
      }
    },
    addToUncategorized(state: { uncategorizedPeople: Person[] }, payload: Person) {
      payload.category = null;
      state.uncategorizedPeople.push(payload);
    },
    removeFromUncategorized(state: { uncategorizedPeople: Person[] }, payload: number) {
      const index = state.uncategorizedPeople.findIndex((item: Person) => item.id === payload);
      if (index >= 0) {
        state.uncategorizedPeople.splice(index, 1);
      }
    },
    setPersonState(
      state: { uncategorizedPeople: Person[] },
      { personId, status }: { personId: number; status: State },
    ) {
      const modifiedPerson = state.uncategorizedPeople.find((person: Person) => person.id === personId);
      if (modifiedPerson) {
        modifiedPerson.state = status;
      }
    },
  },
  actions: {
    getOperationalUnitPeoplePage(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      { pageOptions, operationalUnitId }: { pageOptions: PageOptions; operationalUnitId: number },
    ) {
      const text = rootGetters['filterStorage/getBasicInformationSearchText'];
      return axios
        .get(`/operational-units/${operationalUnitId}/people`, { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setPageableOperationalUnitPeople', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getAll({ dispatch }: { dispatch: Dispatch }) {
      return axios
        .get('/people')
        .then(({ data }) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getCategorizedPeople(
      {
        commit,
        dispatch,
        rootGetters,
      }: {
        commit: Commit;
        dispatch: Dispatch;
        rootGetters: any;
      },
      operationalUnitId: number,
    ) {
      const text = rootGetters['filterStorage/getBasicInformationSearchText'];
      const filterOptions = rootGetters['filterStorage/personFilterStorage/getPersonFilterOptions'];

      const params = new PersonFilterRequest(text, filterOptions);

      return axios
        .get(`/operational-units/${operationalUnitId}/people`, { params })
        .then(({ data }) => {
          if (data) {
            commit('setUncategorizedPeople', data.uncategorizedItems);
            const categories = data.categories.map((category: PersonCategory) => {
              return {
                id: category.id,
                name: category.name,
                items: data.categorizedItems[category.id],
              };
            });
            commit('personCategoryStorage/setCategories', categories, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getNextId({ dispatch }: { dispatch: Dispatch }) {
      return axios
        .get('/people/next-id')
        .then(({ data }) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, formData: FormData) {
      return axios
        .post('/people', formData)
        .then((response) => {
          if (response.status !== HttpStatus.OK_BUT_NOT_FILTERED) {
            if (response.data.category) {
              commit('personCategoryStorage/setPerson', response.data, { root: true });
            } else {
              commit('addToUncategorized', response.data);
            }
          }
          dispatch(
            'showSuccessNotification',
            { message: i18n.t('notification.success.person-addition') },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { request, prevCategory }: { request: PersonRequest; prevCategory: PersonCategory | null },
    ) {
      return axios
        .put(`/people/${request.id}`, request)
        .then((response) => {
          if (response.status === HttpStatus.OK_BUT_NOT_FILTERED) {
            if (prevCategory) {
              commit(
                'personCategoryStorage/removePerson',
                { categoryId: prevCategory.id, personId: response.data.id },
                { root: true },
              );
            } else {
              commit('removeFromUncategorized', response.data.id);
            }
          } else {
            if (prevCategory && !response.data.category) {
              commit(
                'personCategoryStorage/removePerson',
                { categoryId: prevCategory.id, personId: response.data.id },
                { root: true },
              );
              commit('addToUncategorized', response.data);
            } else if (!prevCategory && response.data.category) {
              commit('removeFromUncategorized', response.data.id);
              commit('personCategoryStorage/addPerson', response.data, { root: true });
            } else if (prevCategory && response.data.category) {
              if (prevCategory !== response.data.category) {
                commit(
                  'personCategoryStorage/removePerson',
                  { categoryId: prevCategory.id, personId: response.data.id },
                  { root: true },
                );
                commit('personCategoryStorage/addPerson', response.data, { root: true });
              } else {
                commit('personCategoryStorage/setPerson', response.data, { root: true });
              }
            } else {
              commit('setUncategorizedPerson', response.data);
            }
          }

          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.person-modification', {
                name: `${response.data.lastName} ${response.data.firstName}`,
              }),
            },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, person: Person) {
      return axios
        .delete(`/people/${person.id}`)
        .then(() => {
          if (person.category) {
            commit(
              'personCategoryStorage/removePerson',
              { categoryId: person.category.id, personId: person.id },
              { root: true },
            );
          } else {
            commit('removeFromUncategorized', person.id);
          }
          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.person-deletion', {
                name: `${person.lastName} ${person.firstName}`,
              }),
            },
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getState({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, person: Person) {
      return axios
        .get(`/people/${person.id}/state`)
        .then(({ data }) => {
          if (person.category) {
            commit(
              'personCategoryStorage/setPersonState',
              { category: person.category, personId: person.id, status: data },
              { root: true },
            );
          } else {
            commit('setPersonState', { personId: person.id, status: data });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getPageableOperationalUnitPeople(state: { pageableOperationalUnitPeople: Pageable<Person> }) {
      return state.pageableOperationalUnitPeople;
    },
    getUncategorizedPeople(state: { uncategorizedPeople: Person[] }) {
      return state.uncategorizedPeople;
    },
  },
} as PersonStorage;
