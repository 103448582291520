import axios from 'axios';
import { i18n } from '@/i18n';
import Requirement from '@/model/requirement';
import User from '@/model/user';
import Task from '@/model/task';
import { State } from '@/model/enums/state';
import PageOptions from '@/model/page/page-options';
import RequirementType from '@/model/requirement-type';
import { findModifiedRequirement, adjustState, adjustMainDeadline, adjustPrescibers } from '@/helper/methods';
import PageableRequirement from '@/model/page/pageable-requirement';
import PageableTask from '@/model/page/pageable-task';
import LinkedRequirement from '@/model/linked-requirement';
import RequirementFilterRequest from '@/model/request/filter/requirement-filter-request';
import { RequirementTableType } from '@/model/enums/requirement-table-type';
import { HttpStatus } from '@/model/enums/http-status';
import RequirementRequest from '@/model/request/requirement-request';
import { Module } from 'vuex';

type RequriementModule = Module<
  {
    requirements: Array<PageableRequirement<Requirement>>;
    types: RequirementType[];
    linkedListRequirements: LinkedRequirement[];
    openedDetailedViewRequirement: Requirement | {};
  },
  unknown
>;

export default {
  namespaced: true,
  state: {
    requirements: new Array<PageableRequirement<Requirement>>(),
    types: new Array<RequirementType>(),
    linkedListRequirements: new Array<LinkedRequirement>(),
    openedDetailedViewRequirement: {} as Requirement,
  },
  mutations: {
    setRequirements(state, payload: Array<PageableRequirement<Requirement>>) {
      state.requirements = payload;
    },
    setLinkedListRequirements(state, payload: LinkedRequirement[]) {
      state.linkedListRequirements = payload;
    },
    setOpenedDetailedViewRequirement(state, payload: Requirement) {
      state.openedDetailedViewRequirement = payload;
    },
    updateOpenedDetailedViewRequirement(state, payload: Requirement) {
      const modifiedRequirement: Requirement = findModifiedRequirement(state.requirements, payload);
      state.openedDetailedViewRequirement = modifiedRequirement;
    },
    setCategoryRequirements(state, payload: PageableRequirement<Requirement>) {
      for (const requirementList of state.requirements) {
        if (requirementList.category === payload.category) {
          requirementList.items = requirementList.items.concat(payload.items);
          requirementList.currentPage = payload.currentPage;
          break;
        }
      }
    },
    setRequirementModification(state, payload: Requirement) {
      for (let requirementList of state.requirements) {
        if (requirementList.category === payload.category) {
          const pageableRequirements = new PageableRequirement<Requirement>(
            requirementList,
            requirementList.category,
            requirementList.totalRequirements,
          );
          if (
            payload.mainRequirementId == null &&
            (!payload?.subrequirements || payload?.subrequirements?.length < 1)
          ) {
            if (pageableRequirements.getById(payload.id)) {
              pageableRequirements.setById(payload.id, payload);
            } else {
              pageableRequirements.addItemToInfiniteScroll(payload);
              if (payload.type) {
                requirementList.totalRequirements++;
              }
            }
          } else {
            const mainIndex = requirementList.items.findIndex(
              (requirement: Requirement) => requirement.id === payload.id,
            );
            if (mainIndex > -1) {
              if (
                !requirementList.items[mainIndex].subrequirements ||
                requirementList.items[mainIndex]?.subrequirements.length < payload?.subrequirements.length
              ) {
                requirementList.totalRequirements++;
              }
              pageableRequirements.setById(payload.id, payload);
              requirementList.items[mainIndex].deadline = adjustMainDeadline(requirementList.items[mainIndex]);
            }
          }
          requirementList = pageableRequirements;
          break;
        }
      }
    },
    setFinishedTasks(
      state,
      {
        requirement,
        task,
        finished,
      }: {
        requirement: Requirement;
        task: Task;
        finished: boolean;
      },
    ) {
      const modfiedRequirement: Requirement = findModifiedRequirement(state.requirements, requirement);

      if (Object.keys(modfiedRequirement).length > 0) {
        modfiedRequirement?.pageableTasks?.items.forEach((t: Task) => {
          const nextTask = modfiedRequirement.pageableTasks.items.find(
            (next: Task) => next.taskOrder === task.taskOrder + 1,
          );
          if (finished && t.id === task.id) {
            t.finished = true;
            modfiedRequirement.pageableTasks.totalFinishedItems++;
            if (nextTask) {
              nextTask.previousFinished = true;
            }
          } else if (!finished && t.taskOrder >= task.taskOrder) {
            if (t.taskOrder > task.taskOrder) {
              t.previousFinished = false;
            }
            if (t.finished) {
              t.finished = false;
              modfiedRequirement.pageableTasks.totalFinishedItems = task.taskOrder - 1;
            }
          }
        });
      }
    },
    setConfirmed(state, payload: Requirement) {
      let mainRequirement: Requirement = {} as Requirement;
      const requirementList = state.requirements.find(
        (list: PageableRequirement<Requirement>) => list.category === payload.category,
      );
      if (requirementList) {
        requirementList?.items.forEach((r: Requirement) => {
          if (r.id === payload.id) {
            r.confirmed = !r.confirmed;
            mainRequirement = r;
            if (r?.subrequirements && r.subrequirements.length > 0) {
              r.subrequirements.forEach((subrequirement: Requirement) => {
                subrequirement.confirmed = r.confirmed;
              });
            }
          } else if (r?.subrequirements && r.subrequirements.includes(payload)) {
            r.subrequirements.forEach((subrequirement: Requirement) => {
              if (subrequirement.id === payload.id) {
                subrequirement.confirmed = !subrequirement.confirmed;
                mainRequirement = r;
                r.deadline = adjustMainDeadline(r);
                if (!subrequirement.confirmed) {
                  r.confirmed = false;
                }
              }
            });
          }
        });
      }

      if (mainRequirement?.subrequirements && mainRequirement.subrequirements.length > 0) {
        mainRequirement.state = State.OK;
        for (const r of mainRequirement?.subrequirements) {
          if (r.state === State.EXPIRED && !r.confirmed) {
            mainRequirement.state = State.EXPIRED;
            break;
          } else if (r.state === State.NEAR && !r.confirmed) {
            mainRequirement.state = State.NEAR;
          }
        }
      }
    },
    setProcessOwner(state, { user, requirement }: { user: User; requirement: Requirement }) {
      const modfiedRequirement: Requirement = findModifiedRequirement(state.requirements, requirement);
      if (modfiedRequirement?.processOwnerEmails?.includes(user.email)) {
        modfiedRequirement?.processOwnerEmails.splice(modfiedRequirement?.processOwnerEmails.indexOf(user.email), 1);
      } else {
        modfiedRequirement.processOwnerEmails = modfiedRequirement?.processOwnerEmails?.concat(user.email) ?? [
          user.email,
        ];
      }
    },
    setTask(state, { task, requirement }: { task: Task; requirement: Requirement }) {
      const modfiedRequirement: Requirement = findModifiedRequirement(state.requirements, requirement);
      modfiedRequirement?.pageableTasks?.items.forEach((item: Task) => {
        if (item.id === task.id) {
          item.endDate = task.endDate;
          item.assignee = task.assignee;
        }
      });
    },
    setTypes(state, payload: RequirementType[]) {
      state.types = payload;
    },
    setLoadedTasks(state, { pageableTask, requirement }: { pageableTask: PageableTask; requirement: Requirement }) {
      const modfiedRequirement: Requirement = findModifiedRequirement(state.requirements, requirement);
      modfiedRequirement.pageableTasks = pageableTask;
    },
    addLinkedListRequirement(
      state,
      { requirement, linkedRequirementId }: { requirement: Requirement; linkedRequirementId: number },
    ) {
      const modfiedRequirement: Requirement = findModifiedRequirement(state.requirements, requirement);
      const added = state.linkedListRequirements.find(
        (linkedOptions: LinkedRequirement) => linkedOptions.id === linkedRequirementId,
      );
      if (added) {
        modfiedRequirement.linkedRequirements = modfiedRequirement.linkedRequirements?.concat(added) ?? [added];
      }
    },
    removeLinkedListRequirement(
      state,
      { requirement, linkedRequirementId }: { requirement: Requirement; linkedRequirementId: number },
    ) {
      const modfiedRequirement: Requirement = findModifiedRequirement(state.requirements, requirement);
      modfiedRequirement.linkedRequirements = modfiedRequirement.linkedRequirements.filter(
        (linked: LinkedRequirement) => linked.id !== linkedRequirementId,
      );
    },
    removeRequirement(state, payload: number) {
      state.requirements.forEach((element) => {
        const index: number = element.items.findIndex((x) => x.id === payload);
        if (index >= 0) {
          element.totalRequirements -= element.items[index]?.subrequirements
            ? element.items[index].subrequirements.length
            : 1;
          element.items.splice(index, 1);
        } else {
          element.items.forEach((r) => {
            const subindex = r.subrequirements.findIndex((x) => x.id === payload);
            if (subindex >= 0) {
              element.totalRequirements--;
              r.subrequirements.splice(subindex, 1);
              r.deadline = adjustMainDeadline(r);
              r.prescribers = adjustPrescibers(r);
              r.state = adjustState(r);
            }
          });
        }
      });
    },
  },

  actions: {
    getRequirements(
      { commit, dispatch, rootGetters }: any,
      { pageOptions, category }: { pageOptions: PageOptions; category: string },
    ) {
      const filterOptions = rootGetters['filterStorage/getRequirementFilterOptions'];
      const request = new RequirementFilterRequest(category, filterOptions);
      request.prescribers = filterOptions.prescribers.length ? filterOptions.prescribers.join(',') : '';
      return axios
        .get('/requirement' + (category ? '/category' : ''), {
          params: { ...pageOptions, ...request },
        })
        .then(({ data }) => {
          if (category) {
            commit('setCategoryRequirements', data);
          } else {
            commit('setRequirements', data);
          }
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getById(
      { commit, dispatch },
      { requirementId, tableType }: { requirementId: number; tableType: RequirementTableType },
    ) {
      return axios
        .get(`/requirement/${requirementId}`, { params: { tableType } })
        .then(({ data }) => {
          if (data) {
            commit('setOpenedDetailedViewRequirement', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }, { formData, isRequirement }: { formData: FormData; isRequirement: boolean }) {
      return axios
        .post('/requirement', formData)
        .then((response) => {
          if (HttpStatus.OK_BUT_NOT_FILTERED !== response.status) {
            commit('setRequirementModification', response.data);
          }
          dispatch(
            'showSuccessNotification',
            {
              message: isRequirement
                ? i18n.t('notification.success.requirement-addition')
                : i18n.t('notification.success.subtask-addition'),
            },
            { root: true },
          );
          commit('updateOpenedDetailedViewRequirement', response.data);
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }, requirement: Requirement) {
      return axios
        .delete(`/requirement/${requirement.id}`)
        .then(({ data }) => {
          if (data) {
            dispatch(
              'showSuccessNotification',
              {
                message: i18n.t(
                  requirement.type
                    ? 'notification.success.subtask-deletion'
                    : 'notification.success.requirement-deletion',
                ),
              },
              { root: true },
            ).then((confirm: boolean) => {
              if (confirm) {
                commit('removeRequirement', requirement.id);
              }
            });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deactivate({ commit, dispatch }, requirement: Requirement) {
      return axios
        .post(`/requirement/${requirement.id}/deactivate`)
        .then(() => {
          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t(
                requirement.type
                  ? 'notification.success.subtask-deactivation'
                  : 'notification.success.requirement-deactivation',
              ),
            },
            { root: true },
          ).then((confirm: boolean) => {
            if (confirm) {
              commit('removeRequirement', requirement.id);
            }
          });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modifyProcessOwner({ commit, dispatch }, { user, requirement }: { user: User; requirement: Requirement }) {
      return axios
        .post(`/requirement/${requirement.id}/owner/${user.id}`)
        .then(() => {
          commit('setProcessOwner', { user, requirement });
          commit('updateOpenedDetailedViewRequirement', requirement);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    confirmRequirement({ commit, dispatch }, requirement: Requirement) {
      return axios
        .post(`/requirement/${requirement.id}/confirm`)
        .then(({ data }) => {
          if (data) {
            dispatch(
              'showSuccessNotification',
              {
                message: i18n.t(
                  requirement.confirmed
                    ? requirement.type
                      ? 'notification.success.confirmation-withdraw-subtask'
                      : 'notification.success.confirmation-withdraw-requirement'
                    : requirement.type
                    ? 'notification.success.confirmation-complete-subtask'
                    : 'notification.success.confirmation-complete-requirement',
                ),
              },
              { root: true },
            );
            commit('setConfirmed', requirement);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getTypes({ commit, dispatch }) {
      return axios
        .get('/types')
        .then(({ data }) => {
          commit('setTypes', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getLinkedListRequirements({ commit, dispatch }, requirement: Requirement) {
      return axios
        .get(`/requirement/${requirement.id}/category/all`, { params: { category: requirement.category } })
        .then(({ data }) => {
          commit('setLinkedListRequirements', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addLinkedListRequirement(
      { commit, dispatch },
      { linkedRequirementId, requirement }: { linkedRequirementId: number; requirement: Requirement },
    ) {
      return axios
        .post(`/requirement/${requirement.id}/link/${linkedRequirementId}`)
        .then(() => {
          commit('addLinkedListRequirement', { requirement, linkedRequirementId });
          commit('updateOpenedDetailedViewRequirement', requirement);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    removeLinkedListRequirement(
      { commit, dispatch },
      { linkedRequirementId, requirement }: { linkedRequirementId: number; requirement: Requirement },
    ) {
      return axios
        .delete(`/requirement/${requirement.id}/link/${linkedRequirementId}`)
        .then(() => {
          commit('removeLinkedListRequirement', { requirement, linkedRequirementId });
          commit('updateOpenedDetailedViewRequirement', requirement);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    saveEditedDetails(
      { commit, dispatch, rootGetters },
      { requirement, modificationRequest }: { requirement: Requirement; modificationRequest: RequirementRequest },
    ) {
      const filterOptions = rootGetters['filterStorage/getRequirementFilterOptions'];
      const request = new RequirementFilterRequest(modificationRequest.category, filterOptions);
      modificationRequest.filter = { ...request };
      return axios
        .post(`/requirement/${requirement.id}/details-modification`, modificationRequest)
        .then(({ data }) => {
          commit('setRequirementModification', data);
          commit('updateOpenedDetailedViewRequirement', requirement);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getRequirements(state) {
      return state.requirements;
    },
    getTypes(state) {
      return state.types;
    },
    getLinkedListRequirements(state) {
      return state.linkedListRequirements;
    },
    getOpenedDetailedViewRequirement(state) {
      return state.openedDetailedViewRequirement;
    },
  },
} as RequriementModule;
