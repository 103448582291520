import { i18n } from '@/i18n';
import BasicInformationRequest from '@/model/request/basic-information-request';
import Notification from '@/model/notification';
import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import { ToolCategory } from '@/model/tool-category';
import Tool from '@/model/tool';

export default {
  namespaced: true,
  state: {
    toolCategoryOptions: new Array<ToolCategory>(),
    categories: new Array<ToolCategory>(),
  },
  mutations: {
    setToolCategoryOptions(state: { toolCategoryOptions: ToolCategory[] }, payload: ToolCategory[]) {
      state.toolCategoryOptions = payload;
    },
    setCategories(state: { categories: ToolCategory[] }, payload: ToolCategory[]) {
      state.categories = payload;
    },
    setCategory(state: { categories: ToolCategory[] }, payload: ToolCategory) {
      const index = state.categories.findIndex((item: ToolCategory) => item.id === payload.id);
      if (index >= 0) {
        Object.assign(state.categories[index], payload);
      } else {
        payload.items = [];
        state.categories.push(payload);
      }
    },
    removeCategory(state: { categories: ToolCategory[] }, payload: number) {
      const index = state.categories.findIndex((item: ToolCategory) => item.id === payload);
      if (index >= 0) {
        state.categories.splice(index, 1);
      }
    },
    setTool(state: { categories: ToolCategory[] }, payload: Tool) {
      const category = state.categories.find((item: ToolCategory) => item.id === payload?.category?.id);
      if (category) {
        if (category.items && category.items.length) {
          const index = category.items.findIndex((tool: Tool) => tool.id === payload.id);
          if (index >= 0) {
            Object.assign(category.items[index], payload);
          } else {
            category.items.push(payload);
          }
        } else {
          category.items = [payload];
        }
      }
    },
    removeTool(state: { categories: ToolCategory[] }, { categoryId, toolId }: { categoryId: number; toolId: number }) {
      const category = state.categories.find((item: ToolCategory) => item.id === categoryId);
      const index = category?.items?.findIndex((item: Tool) => item.id === toolId);
      if (index !== undefined && index >= 0) {
        category?.items.splice(index, 1);
      }
    },
    addTool(state: { categories: ToolCategory[] }, payload: Tool) {
      const category = state.categories.find((item: ToolCategory) => item.id === payload.category?.id);
      if (category) {
        category.items.push(payload);
      }
    },
  },
  actions: {
    getToolCategories({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/tool-categories')
        .then(({ data }) => {
          if (data) {
            commit('setToolCategoryOptions', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    add({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .post('/tool-categories', request)
        .then(({ data }) => {
          if (data) {
            commit('setCategory', data);
            dispatch(
              'showSuccessNotification',
              { message: i18n.t('basic-information.category.add-success', { name: data.name }) },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .put('/tool-categories', request)
        .then(({ data }) => {
          if (data) {
            commit('setCategory', data);
            dispatch(
              'showSuccessNotification',
              {
                message: i18n.t('basic-information.category.modify-success', { name: data.name }),
              },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, category: ToolCategory) {
      return axios
        .delete(`/tool-categories/${category.id}`)
        .then(() => {
          commit('removeCategory', category.id);
          if (category.items && category.items.length) {
            category.items.forEach((item: Tool) => commit('toolStorage/addToUncategorized', item, { root: true }));
          }
          dispatch(
            'showSuccessNotification',
            new Notification(i18n.t('basic-information.category.delete-success').toString()),
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getToolCategoryOptions(state: { toolCategoryOptions: ToolCategory[] }) {
      return state.toolCategoryOptions;
    },
    getCategories(state: { categories: ToolCategory[] }) {
      return state.categories;
    },
  },
};
