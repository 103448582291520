import axios from 'axios';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import RequirementType from '@/model/requirement-type';
import { i18n } from '@/i18n';
import TypeModificationRequest from '@/model/request/type-modification-request';
import Notification from '@/model/notification';
import TypeTask from '@/model/type-task';

export default {
  namespaced: true,
  state: {
    types: {} as Pageable<RequirementType>,
    typeTasks: new Array<TypeTask>(),
  },
  mutations: {
    setTypes(state: { types: Pageable<RequirementType> }, payload: Pageable<RequirementType>) {
      state.types = payload;
    },
    setModifiedType(state: { types: Pageable<RequirementType> }, payload: RequirementType) {
      const index = state.types.items.findIndex((type: RequirementType) => type.id === payload.id);
      if (index >= 0) {
        Object.assign(state.types.items[index], payload);
      } else {
        state.types.items.push(payload);
      }
    },
    setTypeTasks(state: { typeTasks: TypeTask[] }, payload: TypeTask[]) {
      state.typeTasks = payload;
    },
    setLoadedTasks(
      state: { types: Pageable<RequirementType> },
      { typeId, pageableTasks }: { typeId: number; pageableTasks: Pageable<TypeTask> },
    ) {
      const type = state.types.items.find((item: RequirementType) => item.id === typeId);
      if (type) {
        type.pageableTasks = pageableTasks;
      }
    },
  },
  actions: {
    getTypesPage({ commit, dispatch, rootGetters }: any, pageOptions: PageOptions) {
      const text = rootGetters['filterStorage/getAdminTypesSearchText'];
      return axios
        .get('/types/pageable', { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setTypes', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }: any, request: TypeModificationRequest) {
      return axios
        .post('/types', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedType', data);
            if (request.id) {
              dispatch(
                'showSuccessNotification',
                {
                  message: i18n.t('admin.type-settings.modify.success', { type: data.name }),
                },
                { root: true },
              );
            } else {
              dispatch(
                'showSuccessNotification',
                { message: i18n.t('admin.type-settings.add.success', { type: data.name }) },
                { root: true },
              );
            }
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ dispatch }: any, id: number) {
      return axios
        .delete(`/types/${id}`)
        .then(({ data }) => {
          if (data) {
            dispatch(
              'showSuccessNotification',
              new Notification(i18n.t('admin.type-settings.delete.success').toString()),
              { root: true },
            );
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getAllTasksByType({ dispatch, commit }: any, id: number) {
      return axios
        .get(`/types/${id}/tasks`)
        .then(({ data }) => {
          if (data) {
            commit('setTypeTasks', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getTasksWithPaging({ dispatch, commit }: any, { page, typeId }: { page: number; typeId: number }) {
      const pageOptions = new PageOptions(page, 6);
      return axios
        .get(`/types/${typeId}/tasks/paging`, { params: pageOptions })
        .then(({ data }) => {
          if (data) {
            commit('setLoadedTasks', { typeId, pageableTasks: data });
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getTypes(state: { types: Pageable<RequirementType> }) {
      return state.types;
    },
    getTypeTasks(state: { typeTasks: TypeTask[] }) {
      return state.typeTasks;
    },
  },
};
