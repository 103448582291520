import Notification from '@/model/notification';
import Swal from 'sweetalert2';
import { i18n } from '@/i18n';
import { AuthenticationStatus } from '@/model/enums/authentication-status';

export default {
  actions: {
    showErrorNotification({ commit }: any, notification: Notification) {
      Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'error',
        showCancelButton: false,
        position: 'center',
      });
    },
    showWarningNotification({ commit }: any, notification: Notification) {
      return Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'warning',
        showCancelButton: false,
        position: 'center',
      }).then((data) => {
        return (data.isConfirmed || data.isDismissed);
      });
    },
    showSuccessNotification({ commit }: any, notification: Notification) {
      return Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'success',
        showCancelButton: false,
        position: 'center',
      }).then((data) => {
        return (data.isConfirmed || data.isDismissed);
      });
    },
    showInfoNotification({ commit }: any, notification: Notification) {
      Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'info',
        showCancelButton: false,
        position: 'center',
      });
    },
    showConfirmNotification({ commit }: any, notification: Notification) {
      return Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: i18n.t('notification.confirm.yes').toString(),
        cancelButtonText: i18n.t('notification.confirm.no').toString(),
        position: 'center',
      }).then((data) => {
        return data.isConfirmed;
      });
    },
    defaultErrorMessage({ dispatch }: any, message: string) {
      if (message && i18n.te(message)) {
        dispatch('showErrorNotification', { message: i18n.t(message) });
      } else if (message !== AuthenticationStatus.GUEST_EXPIRED) {
        dispatch('showErrorNotification', { message: i18n.t('notification.error.default') });
      }
    },
  },
};
