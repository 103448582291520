import axios from 'axios';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import { Commit, Dispatch } from 'vuex';
import Tool from '@/model/tool';
import { HttpStatus } from '@/model/enums/http-status';
import { i18n } from '@/i18n';
import { ToolRequest } from '@/model/request/tool-request';
import { ToolCategory } from '@/model/tool-category';
import { Module } from 'vuex';
import ToolFilterRequest from '@/model/request/filter/tool-filter-request';

type ToolStorageModule = Module<{ pageableOperationalUnitTools: Pageable<Tool>; uncategorizedTools: Tool[] }, any>;

export default {
  namespaced: true,
  state: {
    pageableOperationalUnitTools: {} as Pageable<Tool>,
    uncategorizedTools: new Array<Tool>(),
  },
  mutations: {
    setPageableOperationalUnitTools(state: { pageableOperationalUnitTools: Pageable<Tool> }, payload: Pageable<Tool>) {
      if (payload.currentPage === 0) {
        state.pageableOperationalUnitTools = payload;
      } else {
        state.pageableOperationalUnitTools.items = state.pageableOperationalUnitTools.items.concat(payload.items);
        state.pageableOperationalUnitTools.currentPage = payload.currentPage;
      }
    },
    setUncategorizedTools(state: { uncategorizedTools: Tool[] }, payload: Tool[]) {
      state.uncategorizedTools = payload;
    },
    addToUncategorized(state: { uncategorizedTools: Tool[] }, payload: Tool) {
      payload.category = null;
      state.uncategorizedTools.push(payload);
    },
    setUncategorizedTool(state: { uncategorizedTools: Tool[] }, payload: Tool) {
      const modifiedTool = state.uncategorizedTools.find((tool: Tool) => tool.id === payload.id);
      if (modifiedTool) {
        Object.assign(modifiedTool, payload);
      }
    },
    removeFromUncategorized(state: { uncategorizedTools: Tool[] }, payload: number) {
      const index = state.uncategorizedTools.findIndex((item: Tool) => item.id === payload);
      if (index >= 0) {
        state.uncategorizedTools.splice(index, 1);
      }
    },
  },
  actions: {
    getOperationalUnitToolsPage(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      { pageOptions, operationalUnitId }: { pageOptions: PageOptions; operationalUnitId: number },
    ) {
      const text = rootGetters['filterStorage/getBasicInformationSearchText'];
      return axios
        .get(`/operational-units/${operationalUnitId}/tools`, { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setPageableOperationalUnitTools', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getAll({ dispatch }: { dispatch: Dispatch }) {
      return axios
        .get('/tools')
        .then(({ data }) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getCategorizedTools({ commit, dispatch, rootGetters }, operationalUnitId: number) {
      const text = rootGetters['filterStorage/getBasicInformationSearchText'];
      const filterOptions = rootGetters['filterStorage/toolFilterStorage/getToolFilterOptions'];

      const params = new ToolFilterRequest(text, filterOptions);

      return axios
        .get(`/operational-units/${operationalUnitId}/tools`, { params })
        .then(({ data }) => {
          if (data) {
            commit('setUncategorizedTools', data.uncategorizedItems);
            const categories = data.categories.map((category: ToolCategory) => {
              return {
                id: category.id,
                name: category.name,
                items: data.categorizedItems[category.id],
              };
            });
            commit('toolCategoryStorage/setCategories', categories, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getNextId({ dispatch }: { dispatch: Dispatch }) {
      return axios
        .get('/tools/next-id')
        .then(({ data }) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }, formData: FormData) {
      return axios
        .post('/tools', formData)
        .then((response) => {
          if (response.status !== HttpStatus.OK_BUT_NOT_FILTERED) {
            if (response.data.category) {
              commit('toolCategoryStorage/setTool', response.data, { root: true });
            } else {
              commit('addToUncategorized', response.data);
            }
          }
          dispatch(
            'showSuccessNotification',
            { message: i18n.t('notification.success.tool-addition') },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }, { request, prevCategory }: { request: ToolRequest; prevCategory: ToolCategory }) {
      return axios
        .put(`/tools/${request.id}`, request)
        .then((response) => {
          if (response.status === HttpStatus.OK_BUT_NOT_FILTERED) {
            if (prevCategory) {
              commit(
                'toolCategoryStorage/removeTool',
                { categoryId: prevCategory.id, toolId: response.data.id },
                { root: true },
              );
            } else {
              commit('removeFromUncategorized', response.data.id);
            }
          } else {
            if (prevCategory && !response.data.category) {
              commit(
                'toolCategoryStorage/removeTool',
                { categoryId: prevCategory.id, toolId: response.data.id },
                { root: true },
              );
              commit('addToUncategorized', response.data);
            } else if (!prevCategory && response.data.category) {
              commit('removeFromUncategorized', response.data.id);
              commit('toolCategoryStorage/addTool', response.data, { root: true });
            } else if (prevCategory && response.data.category) {
              if (prevCategory !== response.data.category) {
                commit(
                  'toolCategoryStorage/removeTool',
                  { categoryId: prevCategory.id, toolId: response.data.id },
                  { root: true },
                );
                commit('toolCategoryStorage/addTool', response.data, { root: true });
              } else {
                commit('toolCategoryStorage/setTool', response.data, { root: true });
              }
            } else {
              commit('setUncategorizedTool', response.data);
            }
          }

          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.tool-modification', {
                name: response.data.name,
              }),
            },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }, tool: Tool) {
      return axios
        .delete(`/tools/${tool.id}`)
        .then(() => {
          if (tool.category) {
            commit('toolCategoryStorage/removeTool', { categoryId: tool.category.id, toolId: tool.id }, { root: true });
          } else {
            commit('removeFromUncategorized', tool.id);
          }
          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.tool-deletion', {
                name: tool.name,
              }),
            },
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getPageableOperationalUnitTools(state: { pageableOperationalUnitTools: Pageable<Tool> }) {
      return state.pageableOperationalUnitTools;
    },
    getUncategorizedTools(state: { uncategorizedTools: Tool[] }) {
      return state.uncategorizedTools;
    },
  },
} as ToolStorageModule;
