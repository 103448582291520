import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import { i18n } from '@/i18n';
import OperationalUnit from '@/model/operational-unit';
import { OperationalUnitBaseDetailsRequest } from '@/model/request/operational-unit-base-details-request';
import { findOperationalUnit, setOperationalUnitActivatedState } from '@/helper/methods';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    operationalUnits: new Array<OperationalUnit>(),
  },
  mutations: {
    setOperationalUnits(state: { operationalUnits: OperationalUnit[] }, payload: OperationalUnit[]) {
      state.operationalUnits = payload;
    },
    setOperationalUnit(
      state: {
        operationalUnits: OperationalUnit[];
      },
      { operationalUnit, mainUnitId }: { operationalUnit: OperationalUnit; mainUnitId: number },
    ) {
      if (mainUnitId != null) {
        const mainUnit = findOperationalUnit(state.operationalUnits, mainUnitId);
        if (mainUnit) {
          const index = mainUnit?.subUnits
            ? mainUnit?.subUnits?.findIndex((unit: OperationalUnit) => unit.id === operationalUnit.id)
            : -1;
          index >= 0
            ? Object.assign(mainUnit.subUnits[index], operationalUnit)
            : (mainUnit.subUnits = mainUnit.subUnits ? mainUnit.subUnits.concat(operationalUnit) : [operationalUnit]);
        }
      } else {
        const index = state.operationalUnits.findIndex((unit: OperationalUnit) => unit.id === operationalUnit.id);
        index >= 0
          ? Object.assign(state.operationalUnits[index], operationalUnit)
          : state.operationalUnits.push(operationalUnit);
      }
    },
    removeOperationalUnit(
      state: {
        operationalUnits: OperationalUnit[];
      },
      { deletedId, mainUnitId }: { deletedId: number; mainUnitId: number },
    ) {
      if (mainUnitId != null) {
        const mainUnit = findOperationalUnit(state.operationalUnits, mainUnitId);
        if (mainUnit) {
          const index = mainUnit.subUnits.findIndex((unit: OperationalUnit) => unit.id === deletedId);
          if (index >= 0) {
            mainUnit.subUnits.splice(index, 1);
          }
        }
      } else {
        const index = state.operationalUnits.findIndex((unit: OperationalUnit) => unit.id === deletedId);
        if (index >= 0) {
          state.operationalUnits.splice(index, 1);
        }
      }
    },
    setOperationalUnitDeactivatedState(
      state: {
        operationalUnits: OperationalUnit[];
      },
      { id, deactivated }: { id: number; deactivated: boolean },
    ) {
      const modifiedUnit = findOperationalUnit(state.operationalUnits, id);
      if (modifiedUnit) {
        setOperationalUnitActivatedState(modifiedUnit, deactivated);
      }
    },
  },
  actions: {
    getOperationalUnits({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/operational-units')
        .then(({ data }) => {
          if (data) {
            commit('setOperationalUnits', data);
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { formData, mainUnitId }: { formData: FormData; mainUnitId: number },
    ) {
      return axios
        .post('/operational-units', formData)
        .then(({ data }) => {
          commit('setOperationalUnit', { operationalUnit: data, mainUnitId });

          dispatch(
            'showSuccessNotification',
            { message: i18n.t('notification.success.operational-unit-addition') },
            { root: true },
          );
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modifyBaseDetails(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { request, mainUnitId }: { request: OperationalUnitBaseDetailsRequest; mainUnitId: number },
    ) {
      return axios
        .put(`/operational-units/${request.id}/base-details`, request)
        .then(({ data }) => {
          commit('setOperationalUnit', { operationalUnit: data, mainUnitId });
          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.operational-unit-modification', {
                name: data.name,
              }),
            },
            { root: true },
          );
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { operationalUnit, mainUnitId }: { operationalUnit: OperationalUnit; mainUnitId: number },
    ) {
      return axios
        .delete(`/operational-units/${operationalUnit.id}`)
        .then(() => {
          commit('removeOperationalUnit', { deletedId: operationalUnit.id, mainUnitId });
          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.operational-unit-deletion', {
                name: operationalUnit.name,
              }),
            },
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    turnDeactivatedState(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { id, deactivated }: { id: number; deactivated: boolean },
    ) {
      return axios
        .delete(`/operational-units/${id}/deactivated`)
        .then(() => {
          commit('setOperationalUnitDeactivatedState', { id, deactivated });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getOperationalUnits(state: { operationalUnits: OperationalUnit[] }) {
      return state.operationalUnits;
    },
    getOpenedOperationalUnitName(state: { operationalUnits: OperationalUnit[] }) {
      const operationalUnit = findOperationalUnit(state.operationalUnits, parseInt(router.currentRoute.params?.id, 10));
      return operationalUnit ? operationalUnit.name : '';
    },
  },
};
