import { i18n } from '@/i18n';
import BasicInformationRequest from '@/model/request/basic-information-request';
import Notification from '@/model/notification';
import axios from 'axios';
import { Commit, Dispatch, Module } from 'vuex';
import { PersonCategory } from '@/model/person-category';
import Person from '@/model/person';
import { State } from '@/model/enums/state';

type PersonCategoryStorage = Module<
  {
    personCategoryOptions: PersonCategory[];
    categories: PersonCategory[];
  },
  unknown
>;

export default {
  namespaced: true,
  state: {
    personCategoryOptions: [],
    categories: [],
  },
  mutations: {
    setPersonCategoryOptions(state: { personCategoryOptions: PersonCategory[] }, payload: PersonCategory[]) {
      state.personCategoryOptions = payload;
    },
    setCategories(state: { categories: PersonCategory[] }, payload: PersonCategory[]) {
      state.categories = payload;
    },
    setCategory(state: { categories: PersonCategory[] }, payload: PersonCategory) {
      const index = state.categories.findIndex((item: PersonCategory) => item.id === payload.id);
      if (index >= 0) {
        Object.assign(state.categories[index], payload);
      } else {
        payload.items = [];
        state.categories.push(payload);
      }
    },
    removeCategory(state: { categories: PersonCategory[] }, payload: number) {
      const index = state.categories.findIndex((item: PersonCategory) => item.id === payload);
      if (index >= 0) {
        state.categories.splice(index, 1);
      }
    },
    setPerson(state: { categories: PersonCategory[] }, payload: Person) {
      const category = state.categories.find((item: PersonCategory) => item.id === payload?.category?.id);
      if (category) {
        if (category.items && category.items.length) {
          const index = category.items.findIndex((person: Person) => person.id === payload.id);
          if (index >= 0) {
            Object.assign(category.items[index], payload);
          } else {
            category.items.push(payload);
          }
        } else {
          category.items = [payload];
        }
      }
    },
    removePerson(
      state: { categories: PersonCategory[] },
      { categoryId, personId }: { categoryId: number; personId: number },
    ) {
      const category = state.categories.find((item: PersonCategory) => item.id === categoryId);
      const index = category?.items?.findIndex((item: Person) => item.id === personId);
      if (index !== undefined && index >= 0) {
        category?.items.splice(index, 1);
      }
    },
    addPerson(state: { categories: PersonCategory[] }, payload: Person) {
      const category = state.categories.find((item: PersonCategory) => item.id === payload.category?.id);
      if (category) {
        category.items.push(payload);
      }
    },
    setPersonState(
      state: { categories: PersonCategory[] },
      { category, personId, status }: { category: PersonCategory; personId: number; status: State },
    ) {
      const personCategory = state.categories.find((item: PersonCategory) => item.id === category.id);
      if (personCategory) {
        const modifiedPerson = personCategory.items.find((person: Person) => person.id === personId);
        if (modifiedPerson) {
          modifiedPerson.state = status;
        }
      }
    },
  },
  actions: {
    getPersonCategories({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/person-categories')
        .then(({ data }) => {
          if (data) {
            commit('setPersonCategoryOptions', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    add({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .post('/person-categories', request)
        .then(({ data }) => {
          if (data) {
            commit('setCategory', data);
            dispatch(
              'showSuccessNotification',
              { message: i18n.t('basic-information.category.add-success', { name: data.name }) },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .put('/person-categories', request)
        .then(({ data }) => {
          if (data) {
            commit('setCategory', data);
            dispatch(
              'showSuccessNotification',
              {
                message: i18n.t('basic-information.category.modify-success', { name: data.name }),
              },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, category: PersonCategory) {
      return axios
        .delete(`/person-categories/${category.id}`)
        .then(() => {
          commit('removeCategory', category.id);
          if (category.items && category.items.length) {
            category.items.forEach((item: Person) => commit('personStorage/addToUncategorized', item, { root: true }));
          }
          dispatch(
            'showSuccessNotification',
            new Notification(i18n.t('basic-information.category.delete-success').toString()),
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getPersonCategoryOptions(state: { personCategoryOptions: PersonCategory[] }) {
      return state.personCategoryOptions;
    },
    getCategories(state: { categories: PersonCategory[] }) {
      return state.categories;
    },
  },
} as PersonCategoryStorage;
