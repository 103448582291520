







export default {
  name: 'App',
};
