import Requirement from '@/model/requirement';
import PageableRequirement from '@/model/page/pageable-requirement';
import store from '@/store';
import { i18n } from '@/i18n';
import router from '@/router';
import { State } from '@/model/enums/state';
import { Prescriber } from '@/model/enums/prescriber';
import AdminRequirement from '@/model/admin-requirement';
import Pageable from '@/model/page/pageable';
import { Route } from '@/model/enums/route';
import DocumentContainer from '@/model/document-container';
import RequirementDocument from '@/model/requirement-document';
import Business from '@/model/business';
import OperationalUnit from '@/model/operational-unit';

export function findModifiedRequirement(
  requirements: Array<PageableRequirement<Requirement>>,
  requirement: Requirement,
): Requirement {
  let modifiedRequirement: Requirement = {} as Requirement;
  const requirementList = requirements.find(
    (list: PageableRequirement<Requirement>) => list.category === requirement.category,
  );

  if (requirementList) {
    for (const r of requirementList?.items) {
      if (r.id === requirement.id) {
        modifiedRequirement = r;
        break;
      } else if (r?.subrequirements) {
        const subrequirement = r.subrequirements.find((subreq: Requirement) => subreq.id === requirement.id);
        if (subrequirement) {
          modifiedRequirement = subrequirement;
          break;
        }
      }
    }
  }

  return modifiedRequirement;
}

export function findModifiedRequirementDocument(
  requirements: Array<PageableRequirement<RequirementDocument>>,
  documentContainer: DocumentContainer,
): RequirementDocument {
  let modifiedRequirement = {} as RequirementDocument;
  const requirementList = requirements.find(
    (list: PageableRequirement<RequirementDocument>) => list.category === documentContainer.category,
  );

  if (requirementList) {
    for (const requirement of requirementList?.items) {
      if (requirement.id === documentContainer.requirementId) {
        modifiedRequirement = requirement;
        break;
      } else if (requirement?.subrequirements) {
        const subrequirement = requirement.subrequirements.find(
          (subreq: RequirementDocument) => subreq.id === documentContainer.requirementId,
        );
        if (subrequirement) {
          modifiedRequirement = subrequirement;
          break;
        }
      }
    }
  }

  return modifiedRequirement;
}

export function adminRequirementReset(
  requirements: PageableRequirement<AdminRequirement>,
  modifiedRequirementId: number,
): PageableRequirement<AdminRequirement> {
  const pageabRequirement = new Pageable<AdminRequirement>(requirements);
  const index = pageabRequirement.getIndexById(modifiedRequirementId);

  if (index >= 0) {
    requirements.totalRequirements -= requirements.items[index]?.subrequirements.length ?? 1;
    requirements.items.splice(index, 1);
  } else {
    for (const requirement of requirements.items) {
      const subrequirementIndex = requirement?.subrequirements.findIndex(
        (subrequirement: AdminRequirement) => subrequirement.id === modifiedRequirementId,
      );
      if (subrequirementIndex >= 0) {
        requirement.subrequirements.splice(subrequirementIndex, 1);
        requirements.totalRequirements--;
        if (requirement.subrequirements.length < 1) {
          const mainRequirementIndex = pageabRequirement.getIndexById(requirement.id);
          if (mainRequirementIndex >= 0) {
            requirements.items.splice(mainRequirementIndex, 1);
          }
        }
      }
    }
  }
  return requirements;
}

export function uploadDocument(attachments: File[]): FormData {
  const formData = new FormData();
  attachments.forEach((file: File) => {
    formData.append('file', file);
  });
  return formData;
}

export function adjustMainDeadline(mainRequirement: Requirement): Date {
  const dateArray = [] as Date[];
  mainRequirement.subrequirements.forEach((subrequirement) => {
    if (!subrequirement.confirmed && !subrequirement.type.technical) {
      dateArray.push(subrequirement.deadline);
    }
  });
  const modifiedDate = dateArray.length > 0 ? dateArray.reduce((a, b) => (a < b ? a : b)) : new Date();
  return modifiedDate;
}

export function adjustState(requirement: Requirement): State {
  let state = State.OK;
  if (requirement?.subrequirements) {
    for (const subrequirement of requirement.subrequirements) {
      if (!subrequirement.confirmed) {
        if (Date.now() > new Date(subrequirement.deadline).valueOf()) {
          state = State.EXPIRED;
          break;
        } else if (
          Date.now() >
          new Date(subrequirement.addition).valueOf() +
            calculatePlusDays(subrequirement.addition, subrequirement.deadline)
        ) {
          state = State.NEAR;
          break;
        }
      }
    }
  }
  return state;
}

export function adjustPrescibers(requirement: Requirement): Prescriber[] {
  let prescribers = [] as Prescriber[];
  requirement?.subrequirements.forEach((subrequirement: Requirement) => {
    prescribers = prescribers.concat(subrequirement.prescribers);
  });
  return prescribers.length > 0 ? [...new Set(prescribers)] : [];
}

export async function checkGuestExpiry() {
  if (
    store.getters['currentUserStorage/getCurrentUser'] &&
    store.getters['currentUserStorage/isGuest'] &&
    Date.now() >= new Date(`${store.getters['currentUserStorage/getCurrentUser'].guestExpiryDate} UTC`).valueOf()
  ) {
    await store
      .dispatch('showWarningNotification', { message: i18n.t('notification.guest.log-out') }, { root: true })
      .then((dismissed: any) => {
        if (dismissed) {
          store.dispatch('currentUserStorage/logout');
          router.push({ name: Route.LOGIN });
        }
      });
    return Promise.reject();
  }
}

function calculatePlusDays(startDate: Date, endDate: Date): number {
  return ((new Date(endDate).valueOf() - new Date(startDate).valueOf()) * 70) / 100;
}

export function findBusiness(items: Business[], id: number): Business | undefined {
  const modifiedBusiness = items?.find((business: Business) => business.id === id);
  if (modifiedBusiness) {
    return modifiedBusiness;
  } else if (items) {
    for (const business of items) {
      if (business?.units) {
        const foundBusiness = findBusiness(business?.units, id);
        if (foundBusiness) {
          return foundBusiness;
        }
      }
    }
  }
}

export function findOperationalUnit(items: OperationalUnit[], id: number): OperationalUnit | undefined {
  const modifiedOperationalUnit = items?.find((operationalUnit: OperationalUnit) => operationalUnit.id === id);
  if (modifiedOperationalUnit) {
    return modifiedOperationalUnit;
  } else if (items) {
    for (const operationalUnit of items) {
      if (operationalUnit?.subUnits) {
        const foundOperationalUnit = findOperationalUnit(operationalUnit?.subUnits, id);
        if (foundOperationalUnit) {
          return foundOperationalUnit;
        }
      }
    }
  }
}

export function setOperationalUnitActivatedState(operationalUnit: OperationalUnit, deactivated: boolean) {
  operationalUnit.deactivated = deactivated;
  if (operationalUnit.subUnits) {
    operationalUnit.subUnits.forEach((unit: OperationalUnit) => {
      setOperationalUnitActivatedState(unit, deactivated);
    });
  }
}
