import { i18n } from '@/i18n';
import { PeriodicTerm } from '@/model/enums/periodic-term';

export const periodicTerms = [
  {
    title: i18n.t('dashboard.requirement-addition.periodic.day'),
    value: PeriodicTerm.DAY,
  },
  {
    title: i18n.t('dashboard.requirement-addition.periodic.week'),
    value: PeriodicTerm.WEEK,
  },
  {
    title: i18n.t('dashboard.requirement-addition.periodic.month'),
    value: PeriodicTerm.MONTH,
  },
  {
    title: i18n.t('dashboard.requirement-addition.periodic.year'),
    value: PeriodicTerm.YEAR,
  },
];
