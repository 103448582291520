import { i18n } from '@/i18n';
import Message from '@/model/message';
import axios from 'axios';
import Vue from 'vue';
import { Commit, Dispatch } from 'vuex';

interface MessageState {
  [OperationalUnitId: number]: {
    messages: Message[];
    unseenMessageCount: number;
  };
}

interface UnitRelated {
  unitId: number;
}

interface MessageAction extends UnitRelated {
  messageId: number;
}

interface Action {
  commit: Commit;
  dispatch: Dispatch;
}

export default {
  namespaced: true,
  state: {} as MessageState,
  mutations: {
    setMessages(state: MessageState, { messages, unitId }: UnitRelated & { messages: Message[] }) {
      const object = {
        messages,
        unseenMessageCount: messages.filter((message) => !message.seen).length,
      };

      if (!state[unitId]) {
        Vue.set(state, unitId, object);
      } else {
        state[unitId] = object;
      }
    },

    setUnseenMessageCount(state: MessageState, { unitId, unseenCount }: UnitRelated & { unseenCount: number }) {
      if (!state[unitId]) {
        Vue.set(state, unitId, {
          messages: [],
          unseenMessageCount: unseenCount,
        });
      } else {
        state[unitId].unseenMessageCount = unseenCount;
      }
    },

    setUnseenMessageCountBatch(state: MessageState, counts: Array<UnitRelated & { unseenCount: number }>) {
      counts.forEach(({ unitId, unseenCount }) => {
        if (!state[unitId]) {
          Vue.set(state, unitId, {
            messages: [],
            unseenMessageCount: unseenCount,
          });
        } else {
          state[unitId].unseenMessageCount = unseenCount;
        }
      });
    },

    addMessage(state: MessageState, { message, unitId }: UnitRelated & { message: Message }) {
      state[unitId].messages.push(message);
    },

    setMessageSeen(state: MessageState, { messageId, unitId }: MessageAction) {
      const modifiedMessage = state[unitId].messages.find((message: Message) => message.id === messageId);

      if (modifiedMessage) {
        modifiedMessage.seen = true;
      }
    },

    setMessageConfirmed(state: MessageState, { messageId, unitId }: MessageAction) {
      const modifiedMessage = state[unitId].messages.find((message: Message) => message.id === messageId);
      if (modifiedMessage) {
        modifiedMessage.confirmed = !modifiedMessage.confirmed;
      }
    },

    deceaseUnseenMessages(state: MessageState, { messageId, unitId }: MessageAction) {
      state[unitId].unseenMessageCount--;
      const item = state[unitId].messages.find((e) => e.id === messageId);
      if (item) {
        item.seen = true;
      }
    },
  },
  actions: {
    getMessages({ commit, dispatch }: Action, unitId: number) {
      return axios
        .get(`/messages/${unitId}`)
        .then(({ data }) => {
          if (data) {
            commit('setMessages', { unitId, messages: data });
            // Where is this used?
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getUnseenMessageCount({ commit, dispatch }: Action, unitId: number) {
      return axios
        .get(`/messages/${unitId}/count`)
        .then(({ data }) => {
          if (data) {
            commit('setUnseenMessageCount', { unitId, unseenCount: data });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getUnseenMessageCounts({ commit, dispatch }: Action) {
      return axios
        .get(`/messages/count`)
        .then(({ data }) => {
          if (data) {
            const transformedData = Object.entries(data).map(([key, value]) => ({
              unitId: key,
              unseenCount: value,
            }));

            commit('setUnseenMessageCountBatch', transformedData);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }: Action, { formData, unitId }: UnitRelated & { formData: FormData }) {
      return axios
        .post('/messages', formData)
        .then(({ data }) => {
          if (data) {
            commit('addMessage', { unitId, message: data });
          }
          dispatch(
            'showSuccessNotification',
            {
              message: i18n.t('notification.success.message-addition'),
            },
            { root: true },
          );
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setSeen({ commit, dispatch }: Action, { messageId, unitId }: MessageAction) {
      return axios
        .put(`/messages/${messageId}/seen`)
        .then(() => {
          commit('setMessageSeen', { messageId, unitId });
          commit('deceaseUnseenMessages', { messageId, unitId });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    confirm({ commit, dispatch }: Action, id: number) {
      return axios
        .put(`/messages/${id}/confirm`)
        .then(() => {
          commit('setMessageConfirmed', id);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    isMessageStateLoaded: (state: MessageState) => (unitId: number): boolean => {
      return state[unitId] !== undefined;
    },
    getMessages: (state: MessageState) => (unitId: number) => {
      return state[unitId]?.messages ?? [];
    },
    getUnseenMessageCount: (state: MessageState) => (unitId: number) => {
      return state[unitId]?.unseenMessageCount ?? 0;
    },
  },
};
